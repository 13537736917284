import React from 'react';
import cuid from 'cuid';
import Image from '../../atoms/Image';

const HomeHelp = () => {
  const areas = [
    {
      title: '10+',
      subtitle: 'Years of experience',
    },
    {
      title: '30+',
      subtitle: 'Real estate experts',
    },
    {
      title: '50+',
      subtitle: 'Tools and calculators',
    },
  ];
  return (
    <section className="pt-32 sm:pb-12 bg-[#f3f5f9] px-4">
      <div className="flex flex-col-reverse justify-center items-center text-center sm:text-left md:flex-row gap-0 sm:gap-12 w-full mx-auto max-w-[1000px]">
        <Image
          src="https://imgix.cosmicjs.com/765e80e0-55d3-11ef-b1ea-f56c65dfade9-HomeHelp.png"
          alt="Home help"
          className="rounded relative top-20 sm:top-0 mt-[-50px] sm:mt-0"
          width={400}
          height={189}
        />
        <div className="flex flex-col gap-8">
          <h4 className="tracking-tight text-base sm:text-lg font-semibold text-left sm:text-center">
            Anytime Estimate&apos;s tools and calculators have helped thousands
            turn their real estate estimates into reality
          </h4>
          <div className="flex flex-col items-start sm:flex-row gap-8">
            {areas.map(area => (
              <div
                key={cuid()}
                className="flex flex-row items-center sm:items-start sm:flex-col gap-2"
              >
                <h5 className="font-semibold text-3xl text-primary-500">
                  {area.title}
                </h5>
                <p className="tracking-tight font-normal sm:text-sm">
                  {area.subtitle}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

HomeHelp.defaultProps = {};

HomeHelp.propTypes = {};

export default HomeHelp;
