import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout/Layout';
import SEO from '../components/meta/SEO';
// import HeroSection from '../components/organisms/HeroSection';
import ArticleList from '../components/organisms/ArticleList';
import ZipCtaSection from '../components/organisms/ZipCtaSection';
import HomeHero from '../components/organisms/HomeHero';
import HomeHelp from '../components/organisms/HomeHelp';
import AsSeenIn from '../components/organisms/AsSeenIn';
import RealEstateTools from '../components/organisms/RealEstateTools';

const IndexPage = () => (
  // const { content } = useStaticQuery(
  //   graphql`
  //     {
  //       content: cosmicjsHomepageContent {
  //         metadata {
  //           hero: hero_section {
  //             heading
  //             subheading
  //           }
  //           popularArticles: popular_articles_section {
  //             articles {
  //               url
  //             }
  //           }
  //           zipCta: zip_cta_section {
  //             heading
  //             subheading
  //           }
  //         }
  //       }
  //     }
  //   `,
  // );

  <Layout>
    {/* TODO: Update with real title/description */}
    <SEO
      pathname="/"
      title="Real Estate Calculators Online"
      description="The best FHA loan calculator on the web! Easily estimate the FHA mortgage payment with taxes, insurance, and mip for all 50 states and US territories."
    />
    <HomeHero subheading="Empowering home sellers and buyers with original data and insights from real estate professionals." />
    {/* <HeroSection
        heading={content.metadata.hero.heading}
        subheading={content.metadata.hero.subheading}
      /> */}
    <HomeHelp />
    <AsSeenIn />
    <RealEstateTools />
    <ArticleList />
    {/* <ArticleList
        links={content.metadata.popularArticles.articles.map(({ url }) => url)}
        lazyloadImages={false}
      /> */}

    {/* <ZipCtaSection
        heading={content.metadata.zipCta.heading}
        subheading={content.metadata.zipCta.subheading}
      /> */}
  </Layout>
);
export default IndexPage;
